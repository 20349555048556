<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>证书模板列表</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="toolsbar">
<!--        <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
<!--          <a-button :loading="loading" style="margin-left: 0px" type="primary" @click="start">-->
<!--            刷新-->
<!--          </a-button>-->
<!--        </div>-->
        <!--                <div class="serach_input">-->
        <!--                    <a-input-group compact>-->
        <!--                        <a-select defaultValue="证书模板名">-->
        <!--                            <a-select-option value="username">证书模板名</a-select-option>-->
        <!--                            &lt;!&ndash;                    <a-select-option value="username">证书模板名</a-select-option>&ndash;&gt;-->
        <!--                        </a-select>-->
        <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
        <!--                    </a-input-group>-->
        <!--                </div>-->
      </div>

      <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
        <!--                                                {{data}}-->
        <h1>该页面仅参考</h1>
<!--      {{Certificate_data}}-->
        <div class="cer_001">
            <table style="width:760px;">
              <tr>
                <td height="80px">
                  <div class="certificate">Certificate No.:<span class="Khaki" v-pre>{{certificate}}</span></div>
                  <div class="issue_date">lssue Date: <span class="Khaki" v-pre>{{issue_date}}</span></div>

                </td>
              </tr>
              <tr >
                <td height="360px" colspan="3">
                </td>
              </tr>
              <tr>
                <td height="80px" colspan="3">
                  <div class="name_code Khaki" v-pre>{{sex}}.{{name_code}}</div>
                </td>
              </tr>
              <tr>
                <td height="150px" colspan="3">
                  <div class="project_name Khaki" v-pre>{{project_name}}</div>
                </td>
              </tr>
              <tr>
                <td height="240px" colspan="3">
                </td>
              </tr>
              <tr>
                <td class="sign" style="text-align:center">
                  <img src="https://www.psychomotricite.org/pdf/new/sign_001_optimized.png"/>
                </td>
                <td class="sign">

                </td>
                <td class="sign" style="text-align:center">
                  <img src="https://www.psychomotricite.org/pdf/new/sign_002_optimized.png"/>
                </td>
              </tr>
            </table>
        </div>


      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>


  </a-layout>

</template>

<script>
// import moment from "moment";
import {Modal} from "ant-design-vue";
import Storage from "../common/storage";


// import moment from "moment";
// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      Certificate_id: "",
      Certificate_data: "",
      current: "",
      loading: false,
      servervisible: false,
      uservisible: false,
    };
  },
  created() {
    this.Certificate_id = this.$route.query.Certificate_id
    // 获取第一页内容
    this.doSearch(this.Certificate_id);

  },
  methods: {
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch();
    },

    async doSearch(id) {
      this.loading = true;
      // let now = Date.now();
      console.log("uid", Storage.uid);
      console.log("token", Storage.token);
      let result = await this.$get("/api/admin/certificatesModel/info", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          id: id,
          // t: now,
        }
      });

      if (result.status === true) {

        // let data = [];
        if (result.data === "404 page not found") {
          Modal.error({
            title: "api服务器接口错误"
          });
          return
        }
        let infodatas = result.data;


        // for (let i in infodatas.list) {
        //   let info = infodatas.list[i];
        //
        //
        //   data[i] = {
        //     id: info.id,
        //     user_id: info.user_id,
        //     user_name: info.user_name,
        //     server_id: info.server_id,
        //     server_name: info.server_name,
        //     ban_flag: info.ban_flag,
        //     ban_start_time: moment(info.ban_start_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     ban_end_time: moment(info.ban_end_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     ban_msg: info.ban_msg,
        //     block_flag: info.block_flag,
        //     block_start_time: moment(info.block_start_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     block_end_time: moment(info.block_end_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     block_reason: info.block_reason,
        //     ban_reason: info.ban_reason,
        //     block_msg: info.block_msg,
        //     // res: this.inforesdata,
        //     // start: info.start,
        //     // start: moment(info.start * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //   };
        // }

        this.Certificate_data = infodatas;

        this.loading = false;
      }

      this.loading = false;
    },


  }
};
</script>
<style scoped lang="scss">
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
.cer_001{
  width:900px;
  height: 1350px;
  margin-top: 50px;
  margin-left: auto;
  margin-right:auto;
  padding:70px;
  background-image: url("https://www.psychomotricite.org/pdf/new/last_bg_optimized.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;

  overflow: hidden;
}
.Khaki{color: #b17e1e}
.issue_date{font-size: 18px;font-weight:bolder}
.certificate{font-size: 18px;font-weight:bolder}

.name_code{font-size:42px;font-weight:bolder;text-align:center;width: 100%;margin-bottom: 0px;}
.project_name{font-size:38px;font-weight:bolder;text-align:center;width: 100%;margin-top: 25px;
}
.sign img{width: auto;height: 150px;margin-left: 35px;margin-right: 35px;}
</style>
